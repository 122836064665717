import React from "react";

const AMO_leads = () => {
    return (
        <div style={{ width: '100%', height: '90vh' }}>
            <iframe 
                src="https://telegram.firestorm.team/api/amo_leads/?key=068732f9ded8f813ae3ba019fb4d731cbf4b472c020a6234739a762cddc80dae" 
                style={{ width: '100%', height: '100%', border: 'none', overflow: 'hidden' }}
                title="Заявки АМО"
            />
        </div>
    );
};

export default AMO_leads;
