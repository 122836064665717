// import React from 'react';

// const Index = () => {
//     return (
//         <div style={{ width: '100%', height: '100%' }}>
//             <iframe
//                 src="https://telegram.firestorm.team/users_data"
//                 title="UserData"
//                 width="100%"
//                 height="800px"
//                 style={{ border: 'none' }}
//             />
//         </div>
//     );
// };

// export default Index;


import React, { useEffect } from 'react';



const Index = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
        $(document).ready(function() {
            function loadDialyModalData(funnelId) {
                showFunnelLoadingModal();
                $.get('/funnels/' + funnelId + '/daily_table', function(data) {
                    // Заполняем чекбоксы в модальном окне редактирования данных по дням
                    const dailyDataCheckboxes = $('#dailyDataCheckboxes');
                    dailyDataCheckboxes.empty();
                    data.forEach(function(item) {
                        const checked = item.visible ? 'checked' : '';
                        dailyDataCheckboxes.append(\`
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="\${item.id}" \${checked}>
                                <label class="form-check-label">\${item.name}</label>
                            </div>
                        \`);
                    });
                    hideFunnelLoadingModal();
                });
            }

            function loadSummaryModalData(funnelId) {
                showFunnelLoadingModal();
                $.get('/funnels/' + funnelId + '/summary_table', function(data) {
                    // Заполняем чекбоксы в модальном окне редактирования сводки
                    const displayCheckboxes = $('#displayCheckboxes');
                    displayCheckboxes.empty();
                    data.forEach(function(item) {
                        const checked = item.visible ? 'checked' : '';
                        displayCheckboxes.append(\`
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="\${item.id}" \${checked}>
                                <label class="form-check-label">\${item.name}</label>
                            </div>
                        \`);
                    });
                    const summaryDiv = $('#calculation .funnel');
                    summaryDiv.empty();
                    summaryDiv.append(\`
                                    <div class="text-center">
                                        <label>Настройка подсчёта данных в ячейках</label>
                                    
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Чтобы в ячейках этой таблицы отображались верные данные, нужно для каждой из них выбрать подходящий способ подсчёта данных. По дефолту ставится "Сумма ячеек столбца", а для формул "Перерасчёт"<br><br>
                                            Возможные варианты:<br>
                                            Сумма ячеек столбца - выводит в ячейку сумму всех значений данного столца.<br><br>
                                            Последнее значение - данные в ячейке будут равны последнему полученному значению в этом столбце<br><br>
                                            Среднее значение - высчитывает данные по формуле "сумма всех значений ячеек столбца / кол-во ячеек"<br><br>
                                            Максимальное/минимальное значение берёт мин/макс значение из колонки<br><br>
                                            Перерасчёт - доступно только для формул. Используется когда значение нужно динамически пересчитать по формуле.
                                        </div>
                                    </span>
                                    </div>
                                    \`)
                    data.forEach(metric => {
                        const recalc = metric.metric_type === 'formula' ? \`<option value="recalc" \${metric.calc === 'recalc' ? 'selected' : ''}>Перерасчёт</option>\` : '' 
                        const selectOptions = \`
                            <select class="form-control calc-select" data-id="\${metric.id}">
                                <option value="sum" \${metric.calc === 'sum' ? 'selected' : ''}>Сумма ячеек столбца</option>
                                <option value="last" \${metric.calc === 'last' ? 'selected' : ''}>Последнее значение</option>
                                <option value="max" \${metric.calc === 'max' ? 'selected' : ''}>Максимальное значение</option>
                                <option value="min" \${metric.calc === 'min' ? 'selected' : ''}>Минимальное значение</option>
                                <option value="average" \${metric.calc === 'average' ? 'selected' : ''}>Среднее значение столбца</option>
                                \${recalc}
                            </select>
                        \`;
                        const metricRow = \`
                            <div class="form-group">
                                <label style="color: \${metric.visible ? '#ffffff' : '#808080'}">\${metric.name}</label>
                                \${selectOptions}
                            </div>
                        \`;

                        summaryDiv.append(metricRow);
                    });
                    hideFunnelLoadingModal();
                });
            }

            function fetchData(funnelId) {
                const startDate = $('#start_date').val();
                const endDate = $('#end_date').val();
                showFunnelLoadingModal();
                $.get(\`/funnels/\${funnelId}/data\`, { start_date: startDate, end_date: endDate }, function(data) {
                    // наполнение дневной таблицы
                    const dailyColumns = data.daily.columns;
                    const dailyRows = data.daily.rows;
                    const stillRecords = data.daily.still_records;
                    const dailyTableHeader = $('#table_header');
                    dailyTableHeader.empty();
                    dailyTableHeader.append('<th>Дата</th>');
                    dailyColumns.forEach(column => {
                        dailyTableHeader.append(\`<th data-id="\${column.id}">\${column.name}</th>\`);
                    });

                    const dailyTableBody = $('#table_body');
                    dailyTableBody.empty();
                    dailyRows.forEach(row => {
                        const dailyTr = $('<tr></tr>');
                        dailyTr.append(\`<td id="date-cell" style="background-color: #412024">\${row.date}</td>\`);
                        dailyColumns.forEach(column => {
                            const daily_cell = row[column.id] !== null && row[column.id] !== undefined ? row[column.id] : {};

                            let cellColor = '';
                            if (column.refreshed_dates.includes(row.date)) {
                                cellColor = 'background-color: #292A44;';
                            } else if (daily_cell.changed) {
                                cellColor = 'background-color: #30363C;';
                            }

                            dailyTr.append(\`<td id="value-cell" data-id="\${column.id}" style="\${cellColor}">\${daily_cell.value || ''}</td>\`);
                        });
                        dailyTableBody.append(dailyTr);
                    });
                    const recordsCountDiv = $('#still-records');
                    recordsCountDiv.empty();
                    if (stillRecords) {
                        recordsCountDiv.append(\`<div class="text-center">
                                                    доступно для отображения ещё \${stillRecords} записей
                                                <div>\`)
                    }

                    // наполнение таблицы сводки
                    const summaryColumns = data.summary.columns;
                    const summaryRows = data.summary.rows;
                    const summary_tableHeader = $('#summary_table_header');
                    summary_tableHeader.empty();
                    summaryColumns.forEach(column => {
                        summary_tableHeader.append(\`<th data-id="\${column.id}">\${column.name}</th>\`);
                    });

                    const summaryTableBody = $('#summary_table_body');
                    summaryTableBody.empty();
                    summaryRows.forEach(row => {
                        const summaryTr = $('<tr></tr>');
                        summaryColumns.forEach(column => {
                            const summaryValue = row[column.id] !== null && row[column.id] !== undefined ? row[column.id] : '';
                            summaryTr.append(\`<td data-id="\${column.id}">\${summaryValue}</td>\`);
                        });
                        summaryTableBody.append(summaryTr);
                    });

                    hideFunnelLoadingModal();
                });
            }

            function showFunnelLoadingModal(message = "Загрузка данных...", isError = false) {
                document.getElementById('loadingFunnelOverlay').style.display = 'block';
                document.getElementById('loadingFunnelModal').style.display = 'block';
                const loadingSpinner = document.getElementById('loadingFunnelSpinner');
                const loadingMessage = document.getElementById('loadingFunnelMessage');
                loadingSpinner.style.display = 'inline-block';
                loadingMessage.style.display = 'block';
                loadingMessage.textContent = message;
            }
            function hideFunnelLoadingModal() {
                document.getElementById('loadingFunnelOverlay').style.display = 'none';
                document.getElementById('loadingFunnelModal').style.display = 'none';
            }

            function updateData(funnelId) {
                showFunnelLoadingModal();
                $.post(\`/funnels/\${funnelId}/update\`, function() {
                    fetchData(funnelId);
                    hideFunnelLoadingModal();
                });
            }

            // Функция для отображения уведомления
            function showNotification(message, type) {
                const notificationElement = document.getElementById('notification');
                notificationElement.innerText = message;
                notificationElement.className = \`notification \${type}\`;
                notificationElement.style.display = 'block';

                // Скрыть уведомление через 3 секунды
                setTimeout(() => {
                    notificationElement.style.opacity = 0; // Подготовка к скрытию
                    setTimeout(() => {
                        notificationElement.style.display = 'none'; // Скрываем элемент после затухания
                        notificationElement.style.opacity = 1; // Сброс опacit
                    }, 500); // Время перехода
                }, 3000); // Время показа
            }

            $(document).on('click', '.viewFunnel', function() {
                const funnelId = $(this).data('id');
                $('#viewFunnelModal').modal('show');
                $('#filter').off('click').on('click', function() {
                    fetchData(funnelId);
                });

                $('#update_data').off('click').on('click', function() {
                    updateData(funnelId);
                });


                // Открытие модального окна редактирования строки
                $(document).on('click', '#date-cell', function() {
                    const selectedRow = $(this).closest('tr');
                    const rowData = {
                        date: selectedRow.find('td').eq(0).text(),
                    };
                    // Устанавливаем дату в поле ввода
                    $('#editDate').val(rowData.date);
                    // Сохраняем ссылку на выбранную строку
                    $('#editRowModal').data('date', rowData.date);
                    // Показываем модальное окно
                    $('#editRowModal').modal('show');
                });

                // Обработка кнопки "Сохранить"
                $('#saveRowButton').off('click').on('click', function() {
                    const date = $('#editRowModal').data('date');
                    const newDate = $('#editDate').val();

                    showLoadingModal();
                    $.ajax({
                        url: \`/funnels/\${funnelId}/date_cell\`,
                        method: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify({ date: date, new_date: newDate })
                    }).done(function(response) {
                        fetchData(funnelId);
                        // Закрываем модальное окно
                        $('#editRowModal').modal('hide');
                    }).fail(function(err) {
                        console.error('Ошибка при получении данных:', err);
                        const errorMessage = err.responseJSON ? err.responseJSON.error : 'Неизвестная ошибка';
                        showNotification(errorMessage, 'error');
                    }).always(function() {
                        hideLoadingModal();
                    });

                    
                });

                // Обработка кнопки "Удалить"
                $('#deleteRowButton').off('click').on('click', function() {
                    const date = $('#editRowModal').data('date');
                    if (confirm("Вы уверены, что хотите удалить данную строку со всеми её данными?")) {
                        showLoadingModal();
                        $.ajax({
                            url: \`/funnels/\${funnelId}/date_cell?date=\${date}\`,
                            method: 'DELETE',
                        }).done(function(response) {
                            fetchData(funnelId);
                            // Закрываем модальное окно
                            $('#editRowModal').modal('hide');
                        }).fail(function(err) {
                            console.error('Ошибка при получении данных:', err);
                            const errorMessage = err.responseJSON ? err.responseJSON.error : 'Неизвестная ошибка';
                            showNotification(errorMessage, 'error');
                        }).always(function() {
                            hideLoadingModal();
                        });
                    }
                });

                // для открытия окна редактирования значения ячейки
                $(document).on('click', '#value-cell', function() {
                    const date = $(this).closest('tr').find('td:first').text();
                    const columnId = $(this).data('id');
                    $('#cellColumnId').text(columnId);
                    $('#cellDate').text(date);

                    // Запрос для получения значения и истории изменений
                    showLoadingModal();
                    $.ajax({
                        url: \`/funnels/\${funnelId}/cell\`,
                        method: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify({ date: date, columnId: columnId })
                    }).done(function(response) {
                        $('#cellValue').val(response.value);
                        
                        const historyTableBody = $('#cellHistoryTableBody');
                        historyTableBody.empty(); // Очищаем предыдущие данные

                        if (response.history && Array.isArray(response.history)) {
                            // Если история существует, заполняем таблицу
                            response.history.forEach(item => {
                                const row = \`<tr>
                                    <td>\${item.changed_at}</td>
                                    <td>\${item.changed_value || ''}</td>
                                    <td>\${item.new_value}</td>
                                </tr>\`;
                                historyTableBody.append(row);
                            });
                            $('#cellHistoryContainer').show();
                            $('#tableHistoryContainer').show();
                        } else {
                            $('#cellHistoryContainer').hide();
                            $('#tableHistoryContainer').hide();
                        }

                        const columnName = response.column_name;
                        $('#cellColumnName').text(columnName);
                        $('#cellInfoModal').modal('show');
                    }).fail(function(err) {
                        console.error('Ошибка при получении данных:', err);
                        showNotification(err, 'error');
                    }).always(function() {
                        hideLoadingModal();
                    });
                });

                // Обработчик клика для кнопки "Добавить строку"
                $('#add-row').off('click').on('click', function() {
                    // Открываем модальное окно
                    $('#addRowModal').modal('show');
                    showLoadingModal();
                    $.ajax({
                        url: \`/funnels/\${funnelId}/get_metrics\`,
                        method: 'GET'
                    }).done(function(data) {
                        const tableBody = document.getElementById('metricsTableBody');
                        tableBody.innerHTML = '';
                        data.forEach(item => {
                            const row = document.createElement('tr');
                            row.innerHTML = \`
                                <td>\${item.name}</td>
                                <td><input type="text" class="form-control" data-id="\${item.id}" title="Введите число (целое или с плавающей запятой)"></td>
                            \`;
                            tableBody.appendChild(row);
                        });
                    }).fail(function(err) {
                        console.error('Ошибка при запросе метрик:', err);
                        showNotification(err, 'error');
                    }).always(function() {
                        hideLoadingModal();
                    });
                });

                // Обработчик клика для кнопки "Добавить"
                $('#addMetricsButton').off('click').on('click', function() {
                    const date = document.getElementById('inputDate').value;
                    const metricsValues = [];

                    // Сбор значений из таблицы
                    document.querySelectorAll('#metricsTableBody tr').forEach(row => {
                        const id = row.querySelector('input').dataset.id;
                        const value = row.querySelector('input').value;
                        if (value) {
                            metricsValues.push({ id: id, value: value });
                        }
                    });

                    if (date) {
                        if (metricsValues.length > 0) {
                            showLoadingModal();
                            $.ajax({
                                url: \`/funnels/\${funnelId}/add_row\`,
                                method: 'POST',
                                contentType: 'application/json',
                                data: JSON.stringify({date: date, values: metricsValues})
                            }).done(function(data) {
                                fetchData(funnelId);
                                $('#addRowModal').modal('hide');
                                showNotification('Данные успешно добавлены!', 'success'); // Сообщение об успешном добавлении
                            }).fail(function(err) {
                                console.error('Ошибка при добавлении строки:', err);
                                const errorMessage = err.responseJSON ? err.responseJSON.error : 'Неизвестная ошибка';
                                showNotification(errorMessage, 'error'); // Сообщение об ошибке
                            }).always(function() {
                                hideLoadingModal();
                            });
                        } else {
                            hideLoadingModal();
                            showNotification('Укажите значение хотя-бы для одной метрики!', 'error');
                        }
                    } else {
                        hideLoadingModal();
                        showNotification('Пожалуйста, укажите дату!', 'error');
                    }
                });

                // Обработчик кнопки "Сохранить"
                $('#saveButton').off('click').on('click', function() {
                    const date = $('#cellDate').text();
                    const value = $('#cellValue').val();
                    const columnId = $('#cellColumnId').text();
                    showLoadingModal();
                    // Запрос для сохранения изменения
                    $.ajax({
                        url: \`/funnels/\${funnelId}/cell\`,
                        method: 'PUT',
                        contentType: 'application/json',
                        data: JSON.stringify({ date: date, value: value, columnId: columnId })
                        }).done(function(response) {
                            fetchData(funnelId);
                            $('#cellInfoModal').modal('hide');
                        }).fail(function(err) {
                            console.error('Ошибка при сохранении данных:', err);
                            showNotification(err, 'error');
                        }).always(function() {
                            hideLoadingModal();
                        });
                    });

                $('#edit_summary').off('click').on('click', function() {
                    $('#editSummaryModal').modal('show');
                    loadSummaryModalData(funnelId);
                    $('#saveSummaryChanges').off('click').on('click', function() {
                        showLoadingModal();
                        const summaryData = [];

                        $('#editSummaryModal .calc-select').each(function() {
                            const id = $(this).data('id');
                            const calc = $(this).val();
                            const visible = $('#editSummaryModal input[type="checkbox"][value="' + id + '"]').is(':checked');

                            summaryData.push({
                                id: id,
                                visible: visible,
                                calc: calc
                            });
                        });

                        $.ajax({
                            url: \`/funnels/\${funnelId}/summary_table\`,
                            type: 'POST',
                            contentType: 'application/json',
                            data: JSON.stringify(summaryData),
                        }).done(function(response) {
                            $('#editSummaryModal').modal('hide');
                            fetchData(funnelId);
                        }).always(function() {
                            hideLoadingModal();
                        });
                    });
                });

                $('#edit_daily_data').off('click').on('click', function() {
                   $('#editDailyDataModal').modal('show');
                   loadDialyModalData(funnelId);
                   $('#saveDailyDataChanges').off('click').on('click', function() {
                        showLoadingModal();
                        const dailyData = [];
                        $('#editDailyDataModal input[type="checkbox"]').each(function() {
                            dailyData.push({
                                id: $(this).val(),
                                visible: $(this).is(':checked')
                            });
                        });

                        $.ajax({
                            url: \`/funnels/\${funnelId}/daily_table\`,
                            type: 'POST',
                            contentType: 'application/json',
                            data: JSON.stringify(dailyData),
                        }).done(function(response) {
                            $('#editDailyDataModal').modal('hide');
                            fetchData(funnelId);
                        }).always(function() {
                            hideLoadingModal();
                        });
                   });
                });

                fetchData(funnelId);
            });

            // Функция для форматирования даты в строку с заданным форматом
            function formatDate(date) {
                var year = date.getUTCFullYear();
                var month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
                var day = ('0' + date.getUTCDate()).slice(-2);
                var hour = ('0' + date.getUTCHours()).slice(-2);
                var minute = ('0' + date.getUTCMinutes()).slice(-2);
                var second = ('0' + date.getUTCSeconds()).slice(-2);
                return \`\${day} \${getMonthName(date.getUTCMonth())} \${year} г. в \${hour}:\${minute}:\${second}\`;
            }

            function getMonthName(monthIndex) {
                var monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
                return monthNames[monthIndex];
            }

            function showLoadingModal(message = "Загрузка данных...", isError = false) {
                document.getElementById('loadingOverlay').style.display = 'block';
                document.getElementById('loadingModal').style.display = 'block';
                const loadingSpinner = document.getElementById('loadingSpinner');
                const loadingMessage = document.getElementById('loadingMessage');
                loadingSpinner.style.display = 'inline-block';
                loadingMessage.style.display = 'block';
                loadingMessage.textContent = message;
            }
            function hideLoadingModal() {
                document.getElementById('loadingOverlay').style.display = 'none';
                document.getElementById('loadingModal').style.display = 'none';
            }

            function fetchFunnels() {
                showLoadingModal();
                $.get('/funnels', function(data) {
                    $('#funnelsContainer').empty();
                    data.forEach(function(funnel) {
                        const funnelDiv = $('<div class="funnel">');
                        funnelDiv.append('<h3>' + funnel.name + ' <button class="btn btn-sm btn-info editFunnel" title="Редактировать воронку" data-id="' + funnel.id + '"><i class="fas fa-edit"></i></button></h3>');
                        const actionButton = funnel.is_active 
                            ? '<button class="btn btn-sm btn-warning toggleFunnel" title="Приостановить сбор данных по воронке" data-id="' + funnel.id + '" data-action="stop"><i class="fas fa-pause"></i></button>'
                            : '<button class="btn btn-sm btn-primary toggleFunnel" title="Нажмите чтобы активировать воронку" data-id="' + funnel.id + '" data-action="start"><i class="fas fa-play"></i></button>';
                        funnelDiv.append(actionButton);
                        funnelDiv.append('<button class="btn btn-sm btn-success viewFunnel" title="Открыть воронку для просмотра статистики" data-id="' + funnel.id + '"><i class="fas fa-eye"></i></button>');
                        funnelDiv.append('<button class="btn btn-sm btn-danger deleteFunnel" title="Удалить воронку" data-id="' + funnel.id + '"><i class="fas fa-trash"></i></button>');
                        funnelDiv.append('<p>Описание: ' + funnel.description + '</p>');
                        funnelDiv.append('<p>Бюджет: ' + funnel.budget + '</p>');
                        funnelDiv.append('<p>Создано: ' + formatDate(new Date(funnel.created_at)) + '</p>');

                        $('#funnelsContainer').append(funnelDiv);
                    });
                    hideLoadingModal();
                });
            }


            $('#filterButton').click(function() {
                const startDate = $('#startDate').val();
                const endDate = $('#endDate').val();
                showLoadingModal();
                $.get('/funnels', function(data) {
                    $('#funnelsContainer').empty();
                    data.forEach(function(funnel) {
                        const funnelCreatedAt = new Date(funnel.created_at);
                        if ((!startDate || new Date(startDate) <= funnelCreatedAt) && (!endDate || new Date(endDate) >= funnelCreatedAt)) {
                            const funnelDiv = $('<div class="funnel">');
                            funnelDiv.append('<h3>' + funnel.name + ' <button class="btn btn-sm btn-info editFunnel" title="Редактировать воронку" data-id="' + funnel.id + '"><i class="fas fa-edit"></i></button></h3>');
                            const actionButton = funnel.is_active 
                                ? '<button class="btn btn-sm btn-warning toggleFunnel" title="Приостановить сбор данных по воронке" data-id="' + funnel.id + '" data-action="stop"><i class="fas fa-pause"></i></button>'
                                : '<button class="btn btn-sm btn-primary toggleFunnel" title="Нажмите чтобы активировать воронку" data-id="' + funnel.id + '" data-action="start"><i class="fas fa-play"></i></button>';
                            funnelDiv.append(actionButton);
                            funnelDiv.append('<button class="btn btn-sm btn-success viewFunnel" title="Открыть воронку для просмотра статистики" data-id="' + funnel.id + '"><i class="fas fa-eye"></i></button>');
                            funnelDiv.append('<button class="btn btn-sm btn-danger deleteFunnel" title="Удалить воронку" data-id="' + funnel.id + '"><i class="fas fa-trash"></i></button>');
                            funnelDiv.append('<p>Описание: ' + funnel.description + '</p>');
                            funnelDiv.append('<p>Бюджет: ' + funnel.budget + '</p>');
                            funnelDiv.append('<p>Создано: ' + formatDate(new Date(funnel.created_at)) + '</p>');

                            $('#funnelsContainer').append(funnelDiv);
                        }
                    });
                    hideLoadingModal();
                });
            });

            // старт/стоп воронки
            $(document).on('click', '.toggleFunnel', function() {
                const funnelId = $(this).data('id');
                showLoadingModal();
                $.post('/funnels/' + funnelId + '/toggle', function(funnel) {
                    fetchFunnels();
                    hideLoadingModal();
                });
            });

            // Добавление воронки
            $('#addFunnelForm').submit(function(e) {
                e.preventDefault();
                const funnelData = {
                    name: $('#funnelName').val(),
                    description: $('#funnelDescription').val(),
                    budget: $('#funnelBudget').val()
                };
                showLoadingModal();
                $.ajax({
                    url: '/funnels',
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify(funnelData)
                }).done(function(response) {
                    $('#addFunnelModal').modal('hide');
                    fetchFunnels();
                }).always(function() {
                    hideLoadingModal();
                });
            });

            // Редактирование воронки
            $(document).on('click', '.editFunnel', function() {
                const funnelId = $(this).data('id');
                showLoadingModal();
                $.get('/funnels/' + funnelId, function(funnel) {
                    $('#editFunnelId').val(funnel.id);
                    $('#editFunnelName').val(funnel.name);
                    $('#editFunnelDescription').val(funnel.description);
                    $('#editFunnelBudget').val(funnel.budget);
                    $('#editFunnelModal').modal('show');
                    hideLoadingModal();
                });
                // переключаем тип добавляемой метрики на первый элемент списка
                resetMetricType();
            });

            $('#metricType').change(function() {
                const metricType = $(this).val();
                metricTypeChanged(metricType)
            });

            function metricTypeChanged(metricType) {
                const hostname = window.location.origin;
                let fields = '';
                let addButton = \`
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary">Добавить метрику</button>
                        </div>
                        \`;
                if (metricType === 'youtube') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                <label for="youtubeLink">Ссылка на видео</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Тут нужно указать ссылку на видео, количество просмотров которого будем получать.<br>
                                        Ссылка должна иметь вид: https://www.youtube.com/watch?v=1MbhDaO1qRc
                                    </div>
                                </span>
                                <input type="url" class="form-control" id="youtubeLink" required>
                            </div>
                            \${addButton}
                        </div>\`;
                } else if (metricType === 'api') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                    <label for="apiUrl">URL</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Тут нужно указать API endpoint, откуда будем получать необходимые данные
                                        </div>
                                    </span>
                                    <input type="url" class="form-control" id="apiUrl" required>
                                </div>
                                <div class="form-group">
                                    <label for="apiMethod">Метод</label>
                                    <select class="form-control" id="apiMethod" required>
                                        <option value="GET">GET</option>
                                        <option value="POST">POST</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="apiRequestBody">Тело запроса</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Здесь задаётся тело запроса, которое будет отправлено.<br>
                                            Если метод POST, то писать тело нужно в формате JSON.<br>
                                            Поддерживает подстановку текущей даты в нужном формате<br>
                                            Для этого нужно в значении указать \"{cd}.{cm}.{cy}\"<br>
                                            {cd} это текущий день, {cm} и {cy} это текущий год соответственно.<br>
                                            Также можно прибавлять/убавлять дни от текущей даты.<br>
                                            Например:<br>
                                            {"start_date": "{cd+10}.{cm}.{cy-1}", "end_date": "{cd-7}-{cm}"}
                                        </div>
                                    </span>
                                    <textarea class="form-control" id="apiRequestBody" rows="3"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="apiExtract">Извлекать</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Тут нужно выбрать, что извлекать из ответа сервера.<br>
                                            Если нам нужно подсчитать количество элементов в JSON-ответе, то указываем \"Количество\"<br>
                                            Если нужно получить значение ответа, то указываем \"Значение\"<br>
                                            Для того, чтобы выбрать из ответа конкретное значение, либо получить кол-во элементов по конкретному ключу, можно указать JSON-путь к этому элементу в поле ниже
                                        </div>
                                    </span>
                                    <select class="form-control" id="apiExtract" required>
                                        <option value="value">Значение</option>
                                        <option value="count">Количество</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="apiExtractData">Извлекаемые данные</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Если нужно извлечь какие-то конкретные данные из JSON-ответа сервера, то мы можем указать тут ключ, для которого нужно получить значение.<br>
                                            Можно обращаться к вложенным элементам, перечисляя ключи через точку.<br>
                                            К примеру, сервер в ответе прислал JSON такого вида:<br>
                                            {"data": {count: 1}}<br>
                                            И нам нужно извлечь значение count. Для этого пишем тут:<br>
                                            data.count<br>
                                            и тогда получим это значение (в данном случае 1)
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="apiExtractData">
                                </div>
                            <div class="form-group">
                                <div class="text-center">
                                    <button type="button" class="btn btn-success" id="testRequestButton">Тестовый запрос</button>
                                </div>
                            </div>
                            <div class="form-group">
                                <textarea id="responseText" class="form-control" rows="3" readonly></textarea>
                            </div>
                            \${addButton}
                        </div>\`;
                } else if (metricType === 'yandex_metrika') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                <label for="accountOptionYandex">Аккаунт Яндекс Метрики</label>
                                <div>
                                    <input type="radio" id="newYandexAccount" name="accountOptionYandex" value="new" checked>
                                    <label for="newYandexAccount">Добавить новый аккаунт</label>
                                </div>
                                <div>
                                    <input type="radio" id="existingYandexAccount" name="accountOptionYandex" value="existing">
                                    <label for="existingYandexAccount">Выбрать из добавленных ранее</label>
                                </div>
                            </div>

                            <div id="newYandexAccountFields">
                                <div class="form-group">
                                    <label for="yandexAccountName">Имя аккаунта</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Укажите произвольное имя для аккаунта Яндекс Метрики, чтобы легко его находить среди других.
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="yandexAccountName" required>
                                </div>

                                <div class="form-group">
                                    <label for="yandexAccessToken">Токен доступа (OAuth)</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Укажите OAuth-токен для доступа к API Яндекс Метрики.<br>
                                            Как получить токен: <a href="https://yandex.ru/dev/oauth/doc/dg/concepts/about.html" target="_blank">инструкция по получению токена</a>.<br>
                                            Убедитесь, что токен имеет необходимые права доступа.
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="yandexAccessToken" required>
                                </div>
                            </div>

                            <div id="existingYandexAccountFields" style="display:none;">
                                <div class="form-group">
                                    <label for="existingYandexAccounts">Добавленные аккаунты Яндекс Метрики</label>
                                    <select id="existingYandexAccounts" class="form-control">
                                        <option value="">-- Выберите аккаунт --</option>
                                        <!-- Здесь будут добавлены опции для существующих аккаунтов -->
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="counterId">ID счётчика</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите ID счётчика Яндекс Метрики, данные которого вы хотите получать.<br>
                                        ID счётчика можно найти в интерфейсе Яндекс Метрики.
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="counterId" required>
                            </div>

                            <div class="form-group">
                                <label>Какие данные собираем:</label>
                                <div class="form-check">
                                    <input class="form-check-input yandex-metric" type="checkbox" id="ym_visits" value="ym:s:visits" checked>
                                    <label class="form-check-label" for="ym_visits">Визиты</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input yandex-metric" type="checkbox" id="ym_pageviews" value="ym:s:pageviews" checked>
                                    <label class="form-check-label" for="ym_pageviews">Просмотры страниц</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input yandex-metric" type="checkbox" id="ym_users" value="ym:s:users" checked>
                                    <label class="form-check-label" for="ym_users">Пользователи</label>
                                </div>
                                <!-- Вы можете добавить другие метрики по необходимости -->
                            </div>

                            <div class="form-group">
                                <label for="dimensions">Измерения (опционально)</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите измерения для детализации данных.<br>
                                        Например: ym:s:browser, ym:s:regionCity.<br>
                                        Список доступных измерений можно найти <a href="https://yandex.ru/dev/metrika/doc/api2/api_v1/dimensions/metric_dimensions.html" target="_blank">здесь</a>.
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="dimensions">
                            </div>

                            <div class="form-group">
                                <label for="filters">Фильтры (опционально)</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите фильтры для выборки данных.<br>
                                        Например: ym:s:trafficSource=='organic'.<br>
                                        Подробнее о синтаксисе фильтров можно узнать <a href="https://yandex.ru/dev/metrika/doc/api2/api_v1/filter.html" target="_blank">здесь</a>.
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="filters">
                            </div>
                            \${addButton}
                        </div>\`;
                } else if (metricType === 'telegram') {
                    fields = \`
                            \${addButton}\` 
                } else if (metricType === 'webhook') {
                    fields = \`
                        <div class="funnel">

                            <div class="form-group">
                                <label for="webhookLabel">Метка</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Тут нужно указать произвольную метку. Допустим, есть сервис, который отправляет вебхук при разных событиях. Чтобы принимать разные события, и при этом адрес нашего ендпоинта оставался одинаковым, нам нужно под каждое событие создать вебхук, и указать у всех одинаковую метку. Это свяжет эти метрики.<br>
                                        Если-же нам нужно принимать вебхук из разных сервисов, или из одного сервиса но в разных воронках, то метки должны быть разные (в этом случае для каждой метрики с уникальной меткой будет сгенерирован свой маршрут для приёма вебхука)
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="webhookLabel" required>
                            </div>

                            <div class="form-group">
                                <label for="webhookMethod">Метод запроса</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите, каким методом будет отправлен запрос с сервиса, от которого принимаем вебхук
                                    </div>
                                </span>
                                <select id="webhookMethod" class="form-control" required>
                                    <option value="POST">POST</option>
                                    <option value="GET">GET</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="webhookExpectedParams">Ожидаемые параметры</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите ключевые параметры, которые будут присутствовать в теле запроса вебхука. Например: "event_type", "user_id". 
                                        Эти параметры будут использоваться для обработки данных на сервере.
                                    </div>
                                </span>
                                <textarea class="form-control" id="webhookExpectedParams" rows="3" placeholder='{"event_type": "login", "user_id": "123"}'></textarea>
                            </div>

                            <div class="form-group">
                                <label for="webhookHeaders">Ожидаемые заголовки</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Если ваш вебхук содержит важные заголовки (например, для аутентификации), укажите их здесь.
                                    </div>
                                </span>
                                <textarea class="form-control" id="webhookHeaders" rows="3" placeholder='{"Authorization": "Bearer token"}'></textarea>
                            </div>

                            <div class="form-group">
                                <label for="webhookExtract">Извлекать</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Тут нужно выбрать, что извлекать из запроса.<br>
                                        Если нам нужно подсчитать количество элементов в JSON-ответе, то указываем \"Количество\". Этот метод считает количество элементов в списке/словаре. Чтобы указать, по какому ключу считать значения, нужно этот ключ указать в \"Извлекаемые данные\"<br>
                                        Если нужно получить значение ответа, то указываем \"Значение\"<br>
                                        Для того, чтобы выбрать из ответа конкретное значение, либо получить кол-во элементов по конкретному ключу, можно указать JSON-путь к этому элементу в поле ниже
                                    </div>
                                </span>
                                <select class="form-control" id="webhookExtract" required>
                                    <option value="value">Значение</option>
                                    <option value="count">Количество</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="webhookExtractData">Извлекаемые данные</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Если нужно извлечь какие-то конкретные данные из JSON вебхука, то мы можем указать тут ключ, для которого нужно получить значение.<br>
                                        Можно обращаться к вложенным элементам, перечисляя ключи через точку.<br>
                                        К примеру, сервис прислал JSON такого вида:<br>
                                        {"data": {count: 1}}<br>
                                        И нам нужно извлечь значение count. Для этого пишем тут:<br>
                                        data.count<br>
                                        и тогда получим это значение (в данном случае 1)
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="webhookExtractData">
                            </div>
                            \${addButton}
                        </div>\`;
                } else if (metricType === 'google_sheets') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                <label for="sheetsLink">Ссылка на таблицу</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите полный url-дрес к гугл-таблице, из которой будем получать данные. Данные будут извлекаться из определенных ячеек (которые укажете ниже) по датам.<br><br>
                                        Ссылка должна иметь такой формат:<br>
                                        https://docs.google.com/spreadsheets/d/1hv7s5cleqFjtjwkukwYFDu0h-c8ceqIKe6SFSWm-vH8/edit<br><br>
                                        ВАЖНО:<br>
                                        Для того, чтобы получать данные из гугл-таблицы, нужно предоставить доступ к этой самой таблице сервисному аккаунту. Для этого откройте таблицу, и предоставьте доступ (на чтение) для следующего email:<br>
                                        \${gsheets_service_account}
                                    </div>
                                </span>
                                <input type="url" class="form-control" id="sheetsLink" required>
                            </div>
                            <div class="form-group">
                                <label for="dateColumnName">Имя столбца с датами</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Чтобы правильно извлекать данные по дням, в таблице должен быть столбец с датами. Тут нужно указать имя этого столбца. Регистр символов должен быть такой-же, как в таблице!
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="dateColumnName" required>
                            </div>
                            <div class="form-group">
                                <label for="dateFormat">Формат даты</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Укажите, в каком формате записаны даты в столбце дат.<br>
                                        По умолчанию стоит %Y.%m.%d, что означает, что даты в столбце имеют вид 1970.01.01<br>
                                        Если же даты в столбце записаны в другом формате, к примеру 01-01-1970, то введите тут %d-%m-%Y
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="dateFormat" value="%Y.%m.%d" required>
                            </div>

                            <div class="form-group">
                                <label>Данные для извлечения</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Здесь нужно указать имя страницы и имя столбца, из которого будем получать данные. Если у нас на одной странице несколько столбцов, из которых нужно получать данные, то жмём на плюсик, вводим такое-же имя страницы, и нужного нам столбца. Можно добавлять неограниченное количество страниц и столбцов.
                                    </div>
                                </span>
                                <table class="table" id="sheetTable">
                                    <thead>
                                        <tr>
                                            <th>Имя страницы</th>
                                            <th>Имя столбца</th>
                                            <th>
                                                <div class="text-center">
                                                    <button type="button" class="btn btn-success" title="Добавить поле" id="addSheetRow"><i class="fas fa-plus"></i></button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type="text" class="form-control sheet-name" required></td>
                                            <td><input type="text" class="form-control sheet-columns" required></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            \${addButton}
                        </div>\`;
                } else if (metricType === 'vk_ads') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                <label for="accountOption">Аккаунт с рекламным кабинетом</label>
                                <div>
                                    <input type="radio" id="new" name="accountOption" value="new" checked>
                                    <label for="new">Добавить новый аккаунт</label>
                                </div>
                                <div>
                                    <input type="radio" id="existing" name="accountOption" value="existing">
                                    <label for="existing">Выбрать из добавленных ранее</label>
                                </div>
                            </div>

                            <div id="newAccountFields">
                                <div class="form-group">
                                    <label for="vkAccountName">Имя аккаунта</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Тут нужно указать произвольное имя аккаунта, чтобы легко его находить среди других.
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="vkAccountName" required>
                                </div>

                                <div class="form-group">
                                    <label for="accountType">Тип аккаунта</label>
                                    <select id="accountType" class="form-control">
                                        <option value="client">Клиент</option>
                                        <option value="agency">Агентство</option>
                                    </select>
                                </div>
                                
                                <div class="form-group agency-fields" style="display:none;">
                                    <label for="accessToken">Токен доступа</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Токен доступа, полученный по схеме Agency Client Credentials Grant
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="accessToken">
                                </div>

                                <div class="form-group client-id">
                                    <label for="vkClientId">VK ads client_id</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Тут нужно указать client_id для кабинета, в котором запускается реклама.<br>
                                            Где его получить: https://ads.vk.com/help/articles/help_api<br>
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="vkClientId" required>
                                </div>
                                <div class="form-group client-secret">
                                    <label for="vkClientSecret">VK ads client_secret</label>
                                    <span class="info-circle">
                                        ?
                                        <div class="info-content">
                                            Тут нужно указать client_secret для кабинета, в котором запускается реклама.<br>
                                            Получить его нужно там-же, где и client_id<br>
                                        </div>
                                    </span>
                                    <input type="text" class="form-control" id="vkClientSecret" required>
                                </div>
                            </div>

                            <div id="existingAccountFields" style="display:none;">
                                <div class="form-group">
                                    <label for="existingAccounts">Добавленные аккаунты VK ads</label>
                                    <select id="existingAccounts" class="form-control">
                                        <option value="">-- Выберите аккаунт --</option>
                                        <!-- Здесь будут добавлены опции для существующих аккаунтов -->
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="dataOption">Сбор данных</label>
                                <select id="dataOption" class="form-control">
                                    <option value="campaign">По кампании</option>
                                    <option value="ad">По конкретному объявлению</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Какие данные собираем:</label>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="shows" value="shows" checked>
                                    <label class="form-check-label" for="shows">просмотры</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="clicks" value="clicks" checked>
                                    <label class="form-check-label" for="clicks">клики</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="spent" value="spent" checked>
                                    <label class="form-check-label" for="spent">списания</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="goals" value="goals">
                                    <label class="form-check-label" for="goals">достижений целей</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="cpm" value="cpm">
                                    <label class="form-check-label" for="cpm">цена за 1000 просмотров</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="cpc" value="cpc">
                                    <label class="form-check-label" for="cpc">цена за 1 клик</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="cpa" value="cpa">
                                    <label class="form-check-label" for="cpa">цена 1 цели</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="ctr" value="ctr">
                                    <label class="form-check-label" for="ctr">клики / просмотры</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input vk-stat" type="checkbox" id="cr" value="cr">
                                    <label class="form-check-label" for="cr">достижений целей / клики</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="dataId">Укажите id</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Тут нужно указать id кампании или объявления (в зависимости от того, какое значение указали выше), по которому будут собираться данные.<br>
                                        Убедитесь, что id правильный и существует в системе VK Ads.
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="dataId" required>
                            </div>
                            \${addButton}
                        </div>\`;
                } else if (metricType === 'formula') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                <label for="formulaData">
                                    Формула для подсчёта
                                </label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Введите формулу для расчёта метрики.<br>
                                        Можно использовать id метрик, чтобы подставлять в формулу их значения. Для этого нужно указать {metric_id}, где metric_id это числовой идентификатор нужной метрики.<br>
                                        Чтобы в формуле использовать значение бюджета, нужно указать {b}.<br>
                                        Пример: {b} / {3} + {5}
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="formulaData" required>
                           </div>
                           \${addButton}
                       </div>\`;
                } else if (metricType === 'event') {
                    fields = \`
                        <div class="funnel">
                            <div class="form-group">
                                <label for="eventUtms">Выводить количество для определенных UTM</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        В это поле можно внести UTM-метки (каждая с новой строки).<br>
                                        Логика следующая: если произошел переход/событие на сайте, то это действие фиксируется в базе данных. Если в поле ниже ввести UTM-метки, то в таблице воронки будет выводиться кол-во уникальных переходов/событий, произошедших на отслеживаемой странице, при условии что все UTM-метки из поля ниже совпадают. Таким образом можно отслеживать переходы на сайт из различных источников, и выводить данные по нужным нам меткам.<br>
                                            Если-же не указать никакие метки, то в таблице будет выводиться число всех переходов/событий с любыми метками или без таковых!<br>
                                            Пример: rc=Maxoun
                                    </div>
                                </span>
                                <textarea class="form-control" id="eventUtms" rows="3"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="eventType">Что отслеживаем?</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Здесь задаётся пользовательское действие, которое будет отслеживаться.<br>
                                        Для того, чтобы отслеживание работало, нужно вставить в код страницы в блок script данный JS:<br><br>
                                        (function() {window.tracker = {};tracker.getUserToken = function() {let token = localStorage.getItem('user_token');if (!token) {token = tracker.generateToken();localStorage.setItem('user_token', token);}return token;};tracker.generateToken = function() {return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);};tracker.getUTMData = function() {const params = new URLSearchParams(window.location.search);let utmData = [];params.forEach((value, key) => utmData.push(key+'='+value));utmData.sort();return utmData;};tracker.trackEvent = function(eventType, eventLabel='') {const data = {user_token: tracker.getUserToken(),utm_data: tracker.getUTMData(),event_type: eventType, event_label: eventLabel};fetch('\${hostname}/track', {method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)}).catch(error => {console.error('Error tracking event:', error);});};})();<br><br>Далее нужно сгенерировать ниже код для нужного действия (переход, действие) и тоже вставить в код страницы.
                                    </div>
                                </span>
                                <select class="form-control" id="eventType" required>
                                    <option value="visit">Переход на сайт</option>
                                    <option value="click">Действие на сайте</option>
                                </select>
                            </div>
                            <div class="form-group"  id="labelContainer" style="display: none;">
                                <label for="eventLabel">Краткое описание действия</label>
                                <span class="info-circle">
                                    ?
                                    <div class="info-content">
                                        Тут нужно указать пару слов об элементе, на котором будет отслеживание нажатия.<br>
                                        Это нужно, чтобы иметь возможность различать нажатия на разных элементах. Допустим, у нас есть 2 разные кнопки на сайте, и мы хотим определять кол-во нажатий по каждой из них. Для этого нужно создать 2 метрики, указать их CSS-селекторы, и задать уникальное описание для каждой из них. Если же описания у обеих метрик будут совпадать, то в таблице будет выводиться общий результат! Так что важно указывать уникальные описания для элементов, для которых мы хотим видеть кол-во действий! Если же у нас есть на сайте несколько разных кнопок, но которые совершают одно и тоже действие, то нет смысла по каждой из них делать отдельную метрику. Можно просто указать их CSS селекторы в одной метрике, и считать количество нажатий по любой из них!<br>
                                        Например: \"кнопка реги\", \"ссылка на лендинг\" и тому подобное.
                                    </div>
                                </span>
                                <input type="text" class="form-control" id="eventLabel">
                            </div>
                            <div class="form-group" id="selectorsContainer" style="display: none;">
                                <label for="eventSelectors">Вставьте сюда CSS-селекторы элементов, каждый с новой строки, нажатие по которым нужно отслеживать</label>
                                <textarea class="form-control" id="eventSelectors" rows="3"></textarea>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn btn-success" id="generateCodeButton" style="display: none;">Сгенерировать код</button>
                            </div>
                            <div class="form-group">
                                <label for="generatedCode">Дополнительный код для отслеживания</label>
                                <textarea class="form-control" id="generatedCode" rows="3" readonly></textarea>
                            </div>
                            \${addButton}
                        </div>\`;
                }
                $('#metricFields').html(fields);
                setEventType($('#eventType').val())

                $('input[name="accountOption"]').change(function() {
                    const selectedOption = $(this).val();
                    if (selectedOption === 'new') {
                        $('#newAccountFields').show();
                        $('#existingAccountFields').hide();
                        $('#newAccountFields input').attr('required', true);
                        $('#existingAccountFields select').removeAttr('required');
                    } else {
                        $('#newAccountFields').hide();
                        $('#existingAccountFields').show();
                        $('#newAccountFields input').removeAttr('required');
                        $('#existingAccountFields select').attr('required', true);
                        loadVkAdsAccounts();
                    }
                });

                $('#accountType').change(function() {
                    const selectedOption = $(this).val();
                    if (selectedOption === 'agency') {
                        $('.agency-fields').show();
                        $('#accessToken').attr('required', true);
                        $('#vkClientId').removeAttr('required');
                        $('#vkClientSecret').removeAttr('required');
                        $('.client-id').hide();
                        $('.client-secret').hide();
                    } else {
                        $('.client-id').show();
                        $('.client-secret').show();
                        $('#vkClientId').attr('required', true);
                        $('#vkClientSecret').attr('required', true);
                        $('.agency-fields').hide();
                        $('#accessToken').removeAttr('required');
                    }
                });

                // Обработчик изменения опции аккаунта для Яндекс Метрики
                $(document).on('change', 'input[name="accountOptionYandex"]', function() {
                    const selectedOption = $(this).val();
                    if (selectedOption === 'new') {
                        $('#newYandexAccountFields').show();
                        $('#existingYandexAccountFields').hide();
                        $('#newYandexAccountFields input').attr('required', true);
                        $('#existingYandexAccountFields select').removeAttr('required');
                    } else if (selectedOption === 'existing') {
                        $('#newYandexAccountFields').hide();
                        $('#existingYandexAccountFields').show();
                        $('#newYandexAccountFields input').removeAttr('required');
                        $('#existingYandexAccountFields select').attr('required', true);
                        loadYandexMetrikaAccounts();
                    }
                });

                // Функция для загрузки существующих аккаунтов Яндекс Метрики
                function loadYandexMetrikaAccounts() {
                    // Здесь необходимо реализовать AJAX-запрос к серверу для получения списка ранее сохранённых аккаунтов
                    $.ajax({
                        url: '/metrics/yandex_metrika_accounts',
                        method: 'GET',
                        success: function(accounts) {
                            const $select = $('#existingYandexAccounts');
                            $select.empty();
                            $select.append('<option value="">-- Выберите аккаунт --</option>');
                            accounts.forEach(function(account) {
                                $select.append(\`<option value="\${account.id}">\${account.name}</option>\`);
                            });
                        },
                        error: function() {
                            console.error('Ошибка при получении данных аккаунтов яндекс.метрики');
                            showNotification('Не удалось загрузить данные по аккаунтам Яндекс Метрики', 'error');
                        }
                    });
                }

                // Добавление строки в таблицу
                $('#addSheetRow').click(function() {
                    const tableBody = document.querySelector('#sheetTable tbody');
                    const newRow = document.createElement('tr');
                    newRow.innerHTML = \`
                        <td><input type="text" class="form-control sheet-name" required></td>
                        <td><input type="text" class="form-control sheet-columns" required></td>
                        <td><button type="button" class="btn btn-danger removeSheetRow">Удалить</button></td>
                    \`;
                    tableBody.appendChild(newRow);

                    // Добавляем функционал удаления строки
                    newRow.querySelector('.removeSheetRow').addEventListener('click', () => {
                        newRow.remove();
                    });
                });

                function loadVkAdsAccounts() {
                    $.get('/metrics/vk_ads_accounts', function(data) {
                        const accountSelect = $('#existingAccounts');
                        accountSelect.empty(); // Очищаем текущие опции
                        accountSelect.append('<option value="">-- Выберите аккаунт --</option>'); // Добавляем дефолтный вариант
                        data.forEach(function(account) {
                            accountSelect.append(new Option(account.name, account.id));
                        });
                    });
                }
                    
                $('#eventType').change(function() {
                    const eventType = $(this).val();
                    setEventType(eventType);
                });

                function setEventType(eventType) {
                    if (eventType === 'click') {
                        $('#labelContainer').show();
                        $('#selectorsContainer').show();
                        $('#generateCodeButton').show();
                        $('#generatedCode').val(''); // Очистка поля при смене типа события
                    } else {
                        $('#labelContainer').hide();
                        $('#selectorsContainer').hide();
                        $('#generateCodeButton').hide();
                        const code = \`document.addEventListener('DOMContentLoaded', function() { tracker.trackEvent('visit'); });\`;
                        $('#generatedCode').val(code);
                    }
                }

                $('#generateCodeButton').click(function() {
                    const eventType = $('#eventType').val();
                    const eventSelectors = $('#eventSelectors').val().trim();
                    const eventLabel = $('#eventLabel').val() || '';
                    let code = '';

                    if (eventType === 'click' && eventSelectors) {
                        const selectorsArray = eventSelectors.split(\`\n\`).filter(selector => selector.trim() !== '');
                        const selectorsString = selectorsArray.join(',');
                        code = \`(function() {function addListener() {try {document.querySelectorAll('\${selectorsString}').forEach(element => {if (!element.dataset.trackerBound) {element.addEventListener('click', function(e) {const href = e.target.href || ''; tracker.trackEvent('click', '\${eventLabel}'); if (href !== '') { e.preventDefault(); window.open(href, '_blank');}}); element.dataset.trackerBound = 'true';}});} catch (error) {console.error(error);}} addListener();(new MutationObserver(addListener)).observe(document,{childList:true,subtree:true});})();\`
                    }
                    $('#generatedCode').val(code);
                });
            }

            $('#addMetricForm').submit(function(e) {
                e.preventDefault();
                const metricName = $('#metricName').val();
                const metricType = $('#metricType').val();
                const funnelId = $('#editFunnelId').val();
                let metricData = { metric_type: metricType };

                if (metricType === 'youtube') {
                    metricData.link = $('#youtubeLink').val();
                } else if (metricType === 'webhook') {
                    metricData.url = window.location.origin;
                    metricData.label = $('#webhookLabel').val();
                    metricData.method = $('#webhookMethod').val();
                    metricData.expected_params = $('#webhookExpectedParams').val();
                    metricData.headers = $('#webhookHeaders').val();
                    metricData.extract_type = $('#webhookExtract').val();
                    metricData.extract_data = $('#webhookExtractData').val();
                } else if (metricType === 'api') {
                    metricData.url = $('#apiUrl').val();
                    metricData.method = $('#apiMethod').val();
                    metricData.request_body = $('#apiRequestBody').val();
                    metricData.extract_type = $('#apiExtract').val();
                    metricData.extract_data = $('#apiExtractData').val();
                } else if (metricType === 'formula') {
                    metricData.formula = $('#formulaData').val();
                    metricData.general_summary = 'recalc';
                } else if (metricType === 'event') {
                    metricData.utms = $('#eventUtms').val().split(\`\n\`).filter(selector => selector.trim() !== '');
                    metricData.utms.sort();
                    metricData.event_type = $('#eventType').val();
                    if (metricData.event_type === 'click') {
                        metricData.selectors = $('#eventSelectors').val().split(\`\n\`).filter(selector => selector.trim() !== '');
                        metricData.event_label = $('#eventLabel').val();
                    }
                } else if (metricType === 'vk_ads') {
                    metricData.account_type = $('input[name="accountOption"]:checked').val();
                    metricData.creds_type = $('#accountType').val();
                    if (metricData.account_type === 'new') {
                        metricData.name = $('#vkAccountName').val();
                        if (metricData.creds_type === 'client') {
                            metricData.client_id = $('#vkClientId').val();
                            metricData.client_secret = $('#vkClientSecret').val();
                        } else {
                            metricData.access_token = $('#accessToken').val();
                        }
                    } else {
                        metricData.account = $('#existingAccounts').val();
                    }
                    metricData.get_data_from = $('#dataOption').val();
                    metricData.target_id = $('#dataId').val();
                    metricData.get_data_from = $('#dataOption').val();
                    metricData.target_id = $('#dataId').val();
                    const checkboxes = document.querySelectorAll('.vk-stat:checked');
                    const selectedMetrics = [];
                    checkboxes.forEach(checkbox => {
                        selectedMetrics.push({
                            id: checkbox.id,
                            name: checkbox.nextElementSibling.textContent
                        });
                    });
                    metricData.metrics = selectedMetrics;
                } else if (metricType === 'google_sheets') {
                    metricData.link = $('#sheetsLink').val();
                    metricData.date_column = $('#dateColumnName').val();
                    metricData.date_format = $('#dateFormat').val();
                    metricData.pages = {};

                    $('#sheetTable tbody tr').each(function() {
                        const sheetName = $(this).find('.sheet-name').val();
                        const columnName = $(this).find('.sheet-columns').val();

                        if (!metricData.pages[sheetName]) {
                            metricData.pages[sheetName] = [];
                        }
                        metricData.pages[sheetName].push(columnName);
                    });
                }
                if (metricData.general_summary === undefined){
                    metricData.general_summary = 'sum';
                }
                showLoadingModal();
                $.ajax({
                    url: '/funnels/' + funnelId + '/metrics',
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        name: metricName,
                        data: JSON.stringify(metricData)
                        })
                    }).done(function(response) {
                        $('#metricName').val('');
                        fetchMetrics(funnelId);
                    }).always(function() {
                        hideLoadingModal();
                    });
                });

            function fetchMetrics(funnelId) {
                showLoadingModal();
                $.get('/funnels/' + funnelId + '/metrics', function(server_data) {
                    $('#metricsContainer').empty();
                    const metrics = server_data.metrics
                    window.gsheets_service_account = server_data.service_account // email акка для гугл-таблиц

                    metrics.forEach(function(metric) {
                        const { id, funnelId, position, name, data, createdAt, visible } = metric;
                        const parsedData = data;

                        let metricInfo = \`<b>Название:</b> \${name}<br><b>id:</b> \${id}<br>\`;
                        if (parsedData.metric_type === 'youtube') {
                            metricInfo += \`<b>Тип метрики:</b> Просмотр YouTube<br><b>Ссылка на видео:</b> \${parsedData.link}\`;
                        } else if (parsedData.metric_type === 'webhook') {
                            const extractType = parsedData.extract_type === 'count' ? 'Количество' : 'Значение';
                            metricInfo += \`<b>Тип метрики:</b> Вебхук<br><b>Метка:</b> \${parsedData.label}<br><b>Endpoint:</b> \${parsedData.url}<br><b>Метод:</b> \${parsedData.method}<br><b>Заголовки:</b> \${parsedData.headers}<br><b>Ожидаемые параметры:</b> \${parsedData.expected_params}<br><b>Извлекать:</b> \${extractType}<br><b>Извлекаемые данные:</b> \${parsedData.extract_data}\`;
                        } else if (parsedData.metric_type === 'api') {
                            const extractType = parsedData.extract_type === 'count' ? 'Количество' : 'Значение';
                            metricInfo += \`<b>Тип метрики:</b> Запрос по API<br><b>URL:</b> \${parsedData.url}<br><b>Метод:</b> \${parsedData.method}<br><b>JSON-тело запроса:</b> \${parsedData.request_body}<br><b>Извлекать:</b> \${extractType}<br><b>Извлекаемые данные:</b> \${parsedData.extract_data}\`;
                        } else if (parsedData.metric_type === 'yandex_metrika') {
                            metricInfo += \`<b>Тип метрики:</b> Яндекс Метрика<br><b>Данные:</b> \${parsedData.data}\`;
                        } else if (parsedData.metric_type === 'formula') {
                            metricInfo += \`<b>Тип метрики:</b> Формула<br><b>Формула:</b> \${parsedData.formula}\`;
                        } else if (parsedData.metric_type === 'vk_ads') {
                            const dataFrom = parsedData.get_data_from === 'ad' ? 'Объявлению' : 'Кампании';
                            metricInfo += \`<b>Тип метрики:</b> Реклама VK<br><b>Аккаунт:</b> \${parsedData.account_name}<br><b>Получать данные по:</b> \${dataFrom}<br><b>id рекламы/кампании</b> \${parsedData.target_id}\`;
                        } else if (parsedData.metric_type === 'google_sheets') {
                            const [page, column] = Object.entries(parsedData.pages)[0];
                            metricInfo += \`<b>Тип метрики:</b> Гугл-таблица<br><b>Страница:</b> \${page}<br><b>Колонка:</b> \${column}<br><b>Формат даты:</b> \${parsedData.date_format}<br><b>Ссылка на таблицу</b> \${parsedData.link}\`;
                        } else if (parsedData.metric_type === 'event') {
                            const actionType = parsedData.event_type === 'visit' ? 'Переход на сайт' : 'Действие на сайте';
                            const eventLabel = parsedData.event_label !== undefined ? parsedData.event_label : '';
                            metricInfo += \`<b>Тип метрики:</b> Счётчик действий<br><b>Тип действия:</b> \${actionType}<br><b>Метка:</b> \${eventLabel}<br><b>UTM-метки:</b> \${parsedData.utms}\`;
                            if (parsedData.selectors) {
                                metricInfo += \`<br><b>CSS-селекторы:</b> \${parsedData.selectors}\`;
                            }
                        }

                        const metricDiv = $('<div class="metric">').attr('data-id', id).attr('data-position', position);

                        const metricContent = $('<div class="form-control form-metrics-data">').html(metricInfo);

                        const actionsDiv = $('<div class="metric-actions">');
                        const row1 = $('<div class="actions-row">');
                        const row2 = $('<div class="actions-row">');

                        row1.append('<button class="btn btn-sm btn-secondary move-up" title="Переместить метрику на одну позицию выше" data-id="' + id + '"><i class="fas fa-arrow-up"></i></button>');

                        row2.append('<button class="btn btn-sm btn-secondary move-down" title="Переместить метрику на одну позицию ниже" data-id="' + id + '"><i class="fas fa-arrow-down"></i></button>');
                        
                        
                        row1.append('<button class="btn btn-sm btn-info edit-metric" title="Редактировать метрику" data-id="' + id + '"><i class="fas fa-edit"></i></button>');
                        row2.append('<button class="btn btn-sm btn-danger delete-metric" title="Удалить метрику" data-id="' + id + '"><i class="fas fa-trash"></i></button>');

                        actionsDiv.append(row1);
                        actionsDiv.append(row2);

                        metricDiv.append(metricContent);
                        metricDiv.append(actionsDiv);

                        $('#metricsContainer').append(metricDiv);
                    });


                    // Привязка событий для кнопок
                    $('.move-up').click(function() {
                        moveMetric(funnelId, $(this).data('id'), -1);
                    });
                    $('.move-down').click(function() {
                        moveMetric(funnelId, $(this).data('id'), 1);
                    });
                    $('.edit-metric').click(function() {
                       editMetric(funnelId, $(this).data('id'));
                    });
                    $('.delete-metric').click(function() {
                        deleteMetric(funnelId, $(this).data('id'));
                    });
                    hideLoadingModal();
                });
            }

            $(document).on('click', '.close', function() {
                $(this).closest('.modal').modal('hide');
            });

            // function toggleVisibility(funnelId, metricId) {
            //     showLoadingModal();
            //     $.ajax({
            //         url: '/metrics/' + metricId + '/switch_vision',
            //         method: 'POST',
            //         contentType: 'application/json'
            //         }).done(function(response) {
            //             fetchMetrics(funnelId);
            //         }).always(function() {
            //             hideLoadingModal();
            //     });
            // }

            function moveMetric(currentFunnelId, metricId, direction) {
                showLoadingModal();
                $.ajax({
                    url: '/metrics/' + metricId + '/move',
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({ direction: direction })
                    }).done(function(response) {
                        fetchMetrics(currentFunnelId);
                    }).always(function() {
                        hideLoadingModal();
                });
            }

            function editMetric(currentFunnelId, metricId) {
                alert("Функция в разработке");
                // showLoadingModal();
                // $.get('/metrics/' + metricId, function(metric) {
                //     $('#editMetricId').val(metric.id);
                //     $('#editMetricName').val(metric.name);
                //     $('#editMetricData').val(JSON.stringify(metric.data));
                //     $('#editMetricModal').modal('show');
                //     hideLoadingModal();
                // });
            }

            // $('#editMetricForm').submit(function(e) {
            //     e.preventDefault();
            //     const metricId = $('#editMetricId').val();
            //     const metricData = {
            //         name: $('#editMetricName').val(),
            //         data: JSON.parse($('#editMetricData').val())
            //     };
            //     $.ajax({
            //         url: '/metrics/' + metricId,
            //         method: 'PUT',
            //         contentType: 'application/json',
            //         data: JSON.stringify(metricData),
            //         success: function() {
            //             $('#editMetricModal').modal('hide');
            //             fetchMetrics(currentFunnelId);
            //         }
            //     });
            // });

            function deleteMetric(currentFunnelId, metricId) {
                if (confirm("Вы уверены, что хотите удалить эту метрику?")) {
                    showLoadingModal();
                    $.ajax({
                        url: '/metrics/' + metricId,
                        method: 'DELETE'
                        }).done(function(response) {
                            fetchMetrics(currentFunnelId);
                        }).always(function() {
                            hideLoadingModal();
                    });
                }
            }


            $(document).on('click', '.editFunnel', function() {
                const funnelId = $(this).data('id');
                showLoadingModal();
                $.get('/funnels/' + funnelId, function(funnel) {
                    $('#editFunnelId').val(funnel.id);
                    $('#editFunnelName').val(funnel.name);
                    $('#editFunnelDescription').val(funnel.description);
                    $('#editFunnelBudget').val(funnel.budget);
                    $('#editFunnelModal').modal('show');
                    fetchMetrics(funnelId);
                    hideLoadingModal();
                });
            });

            $('#editFunnelForm').submit(function(e) {
                e.preventDefault();
                const funnelId = $('#editFunnelId').val();
                const funnelData = {
                    name: $('#editFunnelName').val(),
                    description: $('#editFunnelDescription').val(),
                    budget: $('#editFunnelBudget').val()
                };
                showLoadingModal();
                $.ajax({
                    url: '/funnels/' + funnelId,
                    method: 'PUT',
                    contentType: 'application/json',
                    data: JSON.stringify(funnelData)
                    }).done(function(response) {
                        $('#editFunnelModal').modal('hide');
                        fetchFunnels();
                    }).always(function() {
                        hideLoadingModal();
                });
            });

            // Просмотр воронки
            $(document).on('click', '.viewFunnel', function() {
                const funnelId = $(this).data('id');
                $('#viewFunnelIframe').attr('src', '/funnels/' + funnelId + '/view');
                $('#viewFunnelModal').modal('show');
            });

            // Отправка тестового запроса
            $(document).on('click', '#testRequestButton', function() {
                const url = $('#apiUrl').val();
                const method = $('#apiMethod').val();
                const requestBody = $('#apiRequestBody').val();
                const extract = $('#apiExtract').val();
                const extractData = $('#apiExtractData').val();
                showLoadingModal();
                $.ajax({
                    url: '/send_request',
                    method: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify({
                        url: url,
                        method: method,
                        request_body: requestBody,
                        extract_type: extract,
                        extract_data: extractData
                    })
                }).done(function(response) {
                    $('#responseText').val(response);
                }).fail(function(jqXHR) {
                    $('#responseText').val(jqXHR.responseText || 'Произошла ошибка');
                }).always(function() {
                    hideLoadingModal();
                });
            });

            function resetMetricType() {
                // Выбираем первый элемент выпадающего списка
                $('#metricType').prop('selectedIndex', 0);
                metricTypeChanged($('#metricType').val())
            }



            fetchFunnels();
            resetMetricType();
        });
        `;
        
        document.head.appendChild(script);
        

    }, []);






















































   const fullHtml = `
    <!DOCTYPE html>
        <html lang="ru">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Воронки</title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
            <style>
                .form-control {
                    appearance: auto;
                    -webkit-appearance: select;
                    -moz-appearance: select;
                }
                body {
                    background-color: #2A2A32;
                    color: #ffffff;
                    padding-bottom: 20px;
                }
                table {
                    width: 100%;
                    background-color: #fff;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
                }
                tbody {
                    color: #ffffff;
                    background-color: #212529;
                }
                thead {
                    color: #ffffff;
                    background-color: #212529;
                }
                th, td {
                    padding: 10px;
                    text-align: left;
                }
                .table-responsive {
                    width: 100%;
                    height: 90%;
                    overflow-x: auto;
                    overflow-y: auto;
                }
                .form-inline .form-control, .form-inline .btn {
                    margin: 5px;
                    width: 180px;
                }
                .main-container {
                    padding: 20px;
                }
                .main-calendar, .main-btn {
                    width: 200px;
                }
                .funnel {
                    background-color: #22222A;
                    color: ffffff;
                    position: relative;
                    margin-bottom: 20px;
                    padding: 15px;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
                }
                .funnel h3 {
                    margin-bottom: 10px;
                }
                .form-inline {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                }
                p {
                    padding: 0;
                }
                .btn-add, .btn-primary {
                    width: 200px;
                }
                .modal {
                    background-color: rgba(0, 0, 0, 0.5);
                }
                .modal-body {
                    background-color: #2A2A32;
                    color: #ffffff;
                }
                .modal-header {
                    background-color: #B53926;
                    color: #fff;
                }
                .modal-backdrop {
                    background-color: rgba(0, 0, 0, 0.8);
                }
                .editFunnel {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                }
                .viewFunnel {
                    position: absolute;
                    top: 15px;
                    right: 60px;
                }
                .toggleFunnel {
                    position: absolute;
                    top: 60px;
                    right: 60px;
                }
                .deleteFunnel {
                    position: absolute;
                    top: 60px;
                    right: 15px;
                }
                .btn-sm {
                    width: 40px;
                    height: 40px;
                }
                .metric {
                    background-color: #22222A;
                    border-radius: 10px;
                    padding: 10px;
                    margin: 10px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .metric-actions button {
                    margin: 0 5px;
                }
                .modal-dialog.modal-lg {
                    max-width: 97%;
                }
                .info-circle {
                    display: inline-block;
                    float: right;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: #007bff;
                    color: white;
                    font-size: 14px;
                    cursor: pointer;
                    margin-left: 5px;
                    position: relative;
                }

                .info-content {
                    z-index: 5000;
                    display: none;
                    position: absolute;
                    left: 25px;
                    top: -10px;
                    background-color: rgba(0, 0, 0, 0.8);
                    color: white;
                    padding: 10px;
                    border-radius: 4px;
                    font-size: 12px;
                    width: 400px;
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
                    white-space: normal; /* для переноса строк */
                }

                .info-circle:hover .info-content,
                .info-circle .info-content:hover {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                }

                .info-circle .info-content {
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
                }
                .modal-funnel {
                    justify-content: center;
                    align-items: center;
                }
                .form-metrics-data {
                    height: 7em; 
                    overflow-y: auto;
                }
                /* Работает в Firefox */
                * {
                  scrollbar-width: thin;
                  scrollbar-color: #696F75 #444B53;
                }

                /* Работает в Chrome, Edge и Safari */
                *::-webkit-scrollbar {
                  width: 12px;
                }

                *::-webkit-scrollbar-track {
                  background: #444B53;
                }

                *::-webkit-scrollbar-thumb {
                  background-color: #696F75;
                  border-radius: 20px;
                  border: 3px solid #444B53;
                }
                #editFunnelModal {
                    overflow-y: auto;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                }
                #editFunnelModal::-webkit-scrollbar {
                    display: none;
                }
                .metric-actions {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                }

                .actions-row {
                    padding-left: 10px;
                    display: flex;
                    justify-content: flex-start;
                }
                .text-center {
                    text-align: center;
                    width: 100%;
                }

                .btn-square {
                    width: 34px;
                    height: 34px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                }

                .table-header {
                    background-color: #22222A;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .modal-content {
                    margin: auto;
                }

                .modal.fade .modal-dialog {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 100vh;
                }
                .modal {
                    overflow-y: scroll; /* Обеспечивает возможность прокрутки */
                    -ms-overflow-style: none;  /* Скрыть полосу прокрутки в Internet Explorer и Edge */
                    scrollbar-width: none;  /* Скрыть полосу прокрутки в Firefox */
                }

                .modal::-webkit-scrollbar {
                    display: none;  /* Скрыть полосу прокрутки в Chrome, Safari и Opera */
                }

                #value-cell:hover {
                    background-color: #434B53 !important;
                    cursor: pointer;
                }
                #date-cell:hover {
                    background-color: #434B53 !important;
                    cursor: pointer;
                }

                input:invalid {
                  background-color: lightpink !important;
                }

                .notification {
                    position: fixed;
                    top: 10px;
                    left: 50%;
                    transform: translateX(-50%); /* Центрирование по оси X */
                    padding: 15px;
                    border-radius: 5px;
                    z-index: 10000;
                    transition: opacity 0.5s ease;
                }

                .notification.error {
                    background-color: #f8d7da; /* Цвет фона для ошибок */
                    color: #721c24; /* Цвет текста для ошибок */
                    border: 1px solid #f5c6cb; /* Цвет границы для ошибок */
                }

                .notification.success {
                    background-color: #d4edda; /* Цвет фона для успехов */
                    color: #155724; /* Цвет текста для успехов */
                    border: 1px solid #c3e6cb; /* Цвет границы для успехов */
                }
                
                #tableHistoryContainer {
                    max-height: 200px;  /* Установите максимальную высоту */
                    overflow-y: auto;    /* Включите вертикальную прокрутку */
                }

                #funnelTabs {
                    padding-top: 20px;
                }

                .tab-content {
                    padding: 20px;
                    border: 1px solid #ddd;
                    border-top: none;
                    background: #373741;
                }

                .nav-tabs .nlnk {
                    border-radius: 0;
                }

                .nav-tabs .nlnk.active {
                    background-color: #373741;
                    color: white;
                    border-color: #dee2e6 #dee2e6 #373741;
                }

                .nlnk:not(.active) {
                    border: 1px solid #ccc;
                    border-bottom: 1px solid transparent;
                }

            </style>
        </head>
        <body>
            <div id="loadingOverlay" style="display:none; position: fixed; z-index: 9999; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.5);"></div>
            <div id="loadingModal" style="display:none; position: fixed; z-index: 10000; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; text-align: center; background: #333; color: white; border-radius: 10px; width: 300px;">
                <div id="loadingSpinner" class="spinner-border text-light" role="status" style="margin-bottom: 15px;"></div>
                <div id="loadingMessage">Загрузка данных...</div>
            </div>

            <!-- Вкладки -->
            <ul class="nav nav-tabs" id="funnelTabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link nlnk active" id="funnels-tab" data-toggle="tab" href="#funnelsContent" role="tab" aria-controls="funnelsContent" aria-selected="true">Воронки</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link nlnk" id="summary-tab" data-toggle="tab" href="#summaryContent" role="tab" aria-controls="summaryContent" aria-selected="false">Сводная</a>
                </li>
            </ul>

            <!-- Содержимое вкладок -->
            <div class="tab-content" id="funnelTabsContent">
                <!-- Вкладка Воронки -->
                <div class="tab-pane fade show active" id="funnelsContent" role="tabpanel" aria-labelledby="funnels-tab">
                    <div class="container">

                        <div class="container main-container">
                            <div class="row justify-content-center mb-3">
                                <div class="col-auto">
                                    <input type="date" id="startDate" class="form-control main-calendar">
                                </div>
                                <div class="col-auto">
                                    <input type="date" id="endDate" class="form-control main-calendar">
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <button id="filterButton" class="btn btn-primary main-btn">Фильтр</button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success btn-add main-btn" data-toggle="modal" data-target="#addFunnelModal"><i class="fas fa-plus"></i> Добавить воронку</button>
                                </div>
                            </div>
                            <div id="funnelsContainer" class="mt-4"></div>
                        </div>
                        
                        <!-- Модальное окно добавления воронки -->
                        <div class="modal fade" id="addFunnelModal" tabindex="-1" role="dialog" aria-labelledby="addFunnelModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addFunnelModalLabel">Добавить воронку</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form id="addFunnelForm">
                                            <div class="form-group">
                                                <label for="funnelName">Название</label>
                                                <input type="text" class="form-control" id="funnelName" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="funnelDescription">Описание</label>
                                                <textarea class="form-control" id="funnelDescription" rows="3"></textarea>
                                            </div>
                                            <div class="form-group">
                                                <label for="funnelBudget">Бюджет</label>
                                                <input type="text" class="form-control" id="funnelBudget" required>
                                            </div>
                                            <div class="text-center">
                                                <button type="submit" class="btn btn-primary">Сохранить</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Модальное окно для просмотра воронки -->
                        <div class="modal fade" id="viewFunnelModal" tabindex="-1" role="dialog" aria-labelledby="viewFunnelModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="viewFunnelModalLabel">Просмотр Воронки</h5>
                                        <div class="form-group">
                                            <span class="info-circle">
                                                ?
                                                <div class="info-content">
                                                    Это основное окно для просмотра собранных данных по воронке. Тут имеется 2 таблицы: верхняя отображает сводку по данным за всё время, или (если указан диапазон дат в фильтре) за указанный период. Ниже находится таблица, в которой выводятся данные за конкретные дни. Нажатием на ячейку откроется панель изменения значения этой ячейки (можно добавлять/менять значения вручную). Если данные в ячейке были изменены вручную, то это действие будет отображаться в истории изменения данных ячейки.<br><br>
                                                    Ячейки в нижней таблице могут быть подсвечены разными цветами:<br><br>
                                                    -светлосерый: означает что значение данной ячейки было изменено вручную (т.е. у этой ячейки есть история ручного изменения ячейки. Просмотреть историю можно нажав на ячейку)<br><br>
                                                    -синий: данные в таких ячейках будут обновлены автоматически. Сбор данных для ячеек происходит раз в несколько минут. Собранные данные попадают в свои ячейки. Но, к примеру, для ВК-метрик, данные собираются и записываются за 3 дня (сегодня, вчера, позавчера). Поэтому, вручную изменять значение таких ячеек не имеет смысла, т.к. введённые вручную данные всё-равно в скором времени будут перезаписаны автоматически!
                                                </div>
                                            </span>
                                        </div>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body modal-funnel">
                                        <div id="loadingFunnelOverlay" style="display:none; position: fixed; z-index: 9999; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.5);"></div>
                                        <div id="loadingFunnelModal" style="display:none; position: fixed; z-index: 10000; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; text-align: center; background: #333; color: white; border-radius: 10px; width: 300px;">
                                            <div id="loadingFunnelSpinner" class="spinner-border text-light" role="status" style="margin-bottom: 15px;"></div>
                                            <div id="loadingFunnelMessage">Загрузка данных...</div>
                                        </div>

                                        <div class="form-inline mt-3">
                                            <input type="date" id="start_date" class="form-control" placeholder="Начало">
                                            <input type="date" id="end_date" class="form-control" placeholder="Конец">
                                            <button id="filter" class="btn btn-primary">Фильтр</button>
                                            <button id="update_data" class="btn btn-secondary">Обновить данные</button>
                                        </div>
                                        
                                        <div class="modal-header table-header">
                                            <h5 class="modal-title text-center" id="viewFunnelModalLabel">Сводка за период</h5>
                                            <button id="edit_summary" type="button" class="btn btn-square btn-info edit-summary" title="Настройки отображения столбцов и подсчёта данных" data-toggle="modal" data-target="#summaryEditModal">
                                                <i class="fas fa-edit"></i>
                                            </button>
                                        </div>

                                        <div class="table-responsive">
                                            <table id="summary_table" class="table">
                                                <thead>
                                                    <tr id="summary_table_header"></tr>
                                                </thead>
                                                <tbody id="summary_table_body"></tbody>
                                            </table>
                                        </div>

                                        <div class="modal-header table-header">
                                            <h5 class="modal-title text-center" id="viewFunnelModalLabel">Данные по дням</h5>
                                            <div class="metric-actions"> 
                                                <div class="actions-row">
                                                    <button id="add-row" type="button" class="btn btn-square btn-warning add-row" title="Добавить строку в таблицу">
                                                        <i class="fas fa-plus"></i>
                                                    </button>

                                                    <button id="edit_daily_data" type="button" class="btn btn-square btn-info edit-daily-data" title="Настройки отображения столбцов" data-toggle="modal" data-target="#dailyDataEditModal">
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                </div>
                                            </div>      
                                        </div>

                                        <div class="table-responsive">
                                            <table id="data_table" class="table">
                                                <thead>
                                                    <tr id="table_header"></tr>
                                                </thead>
                                                <tbody id="table_body"></tbody>
                                            </table>
                                            <div id="still-records">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Модальное окно для добавления строки -->
                        <div class="modal fade" id="addRowModal" tabindex="-1" role="dialog" aria-labelledby="addRowModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addRowModalLabel">Добавление строки</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="funnel">
                                            <!-- Календарь для выбора даты -->
                                            <div class="form-group">
                                                <span class="info-circle">
                                                    ?
                                                    <div class="info-content">
                                                        У каждой строки в таблице есть своя дата. Тут нужно указать дату для новой строки, которую добавляем. Важно, чтобы дата была уникальной, так как в таблице не может быть несколько строк с одинаковой датой. В этом случае, новая строка просто не будет добавлена!   
                                                    </div>
                                                </span>
                                                <div class="text-center">
                                                    <label for="inputDate">Укажите дату для добавляемой строки</label>
                                                </div>
                                                <div class="form-group">
                                                    <input type="date" id="inputDate" class="form-control" required>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Таблица для отображения метрик -->
                                        <div class="form-group">
                                            <div class="table-responsive">
                                                <table id="metricsTable" class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Имя метрики</th>
                                                            <th>Значение</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="metricsTableBody">
                                                        <!-- Данные будут добавлены динамически -->
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- Кнопка добавления -->
                                            <div class="text-center">
                                                <button id="addMetricsButton" type="button" class="btn btn-primary mt-3">Добавить</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="cellInfoModal" tabindex="-1" role="dialog" aria-labelledby="cellInfoModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="cellInfoModalLabel">Редактирование значения</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="columnId" id="cellColumnId" style="display:none;"></div>
                                    <div class="modal-body">
                                        <div class="text-center">
                                            <p><span id="cellColumnName"></span></p>
                                            <p><span id="cellDate"></span></p>
                                        </div>
                                        <div class="funnel">
                                            <div class="form-group">
                                                <label for="cellValue">Значение:</label>
                                                <input type="text" class="form-control" id="cellValue" pattern="^\\d*(\\.\\d+)?$" title="Пожалуйста, введите число (целое или с плавающей запятой)" required />
                                            </div>
                                            <div class="form-group" id="cellHistoryContainer" style="display: none;">
                                                <label for="cellHistory">История ручного изменения значения:</label>
                                                <div class="form-group" id="tableHistoryContainer" style="display: none;">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Время</th>
                                                                <th>Было</th>
                                                                <th>Стало</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="cellHistoryTableBody">
                                                            <!-- Данные будут вставлены сюда -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button type="button" class="btn btn-primary" id="saveButton">Сохранить</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Модальное окно для редактирования строки -->
                        <div class="modal fade" id="editRowModal" tabindex="-1" role="dialog" aria-labelledby="editRowModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editRowModalLabel">Редактирование строки</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="funnel">
                                            <div class="form-group">
                                                <span class="info-circle">
                                                    ?
                                                    <div class="info-content">
                                                        Тут можно изменить дату строки. В этом случае вся строка со всеми данными за этот день будет перенесена в позицию с указанной датой.<br>
                                                        Если же удалить строку, то все данные этой строки будут безвозвратно удалены!
                                                    </div>
                                                </span>
                                                <div class="text-center">
                                                    <label for="editDate">Новая дата:</label>
                                                    <input type="date" class="form-control" id="editDate" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-inline mt-3 modal-funnel">
                                                <button type="button" class="btn btn-danger form-control" id="deleteRowButton">Удалить</button>
                                                <button type="button" class="btn btn-primary form-control" id="saveRowButton">Сохранить</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Модальное окно для редактирования сводки -->
                        <div class="modal fade" id="editSummaryModal" tabindex="-1" role="dialog" aria-labelledby="editSummaryModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editSummaryModalLabel">Настройки таблицы</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="form-group">
                                            <!-- Вкладки -->
                                            <ul class="nav nav-tabs" id="editSummaryTabs" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link nlnk active" id="display-tab" data-toggle="tab" href="#display" role="tab">Отображение</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link nlnk" id="calculation-tab" data-toggle="tab" href="#calculation" role="tab">Подсчёт</a>
                                                </li>
                                            </ul>
                                            <!-- Вкладки контент -->
                                            <div class="tab-content" id="editSummaryTabsContent">
                                                <div class="tab-pane fade show active" id="display" role="tabpanel" aria-labelledby="display-tab">
                                                    <div class="form-group">
                                                        <div class="funnel">
                                                            <div class="text-center">
                                                                <label>Какие столбцы отображать в таблице?</label>
                                                            </div>
                                                            <!-- Содержимое первой вкладки (чекбоксы) -->
                                                            <div id="displayCheckboxes"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="calculation" role="tabpanel" aria-labelledby="calculation-tab">
                                                    <div class="form-group">
                                                        <div class="funnel">
                                                            <!-- Содержимое второй вкладки (подсчёт) -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button type="button" class="btn btn-primary" id="saveSummaryChanges">Сохранить</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Модальное окно для редактирования данных по дням -->
                        <div class="modal fade" id="editDailyDataModal" tabindex="-1" role="dialog" aria-labelledby="editDailyDataModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editDailyDataModalLabel">Настройки таблицы</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="funnel">
                                            <div class="text-center">
                                                <label>Какие столбцы отображать в таблице?</label>
                                            </div>
                                            <!-- Содержимое модального окна (чекбоксы) -->
                                            <div id="dailyDataCheckboxes"></div>
                                        </div>
                                        <div class="text-center">
                                            <button type="button" class="btn btn-primary" id="saveDailyDataChanges">Сохранить</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Модальное окно редактирования воронки -->
                        <div class="modal fade" id="editFunnelModal" tabindex="-1" role="dialog" aria-labelledby="editFunnelModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="editFunnelModalLabel">Редактировать Воронку</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <ul class="nav nav-tabs" id="editFunnelTabs" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active nlnk" id="editDetailsTab" data-toggle="tab" href="#editDetails" role="tab" aria-controls="editDetails" aria-selected="true">Детали</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link nlnk" id="editMetricsTab" data-toggle="tab" href="#editMetrics" role="tab" aria-controls="editMetrics" aria-selected="false">Метрики</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="editFunnelTabContent">
                                            <div class="tab-pane fade show active" id="editDetails" role="tabpanel" aria-labelledby="editDetailsTab">
                                                <form id="editFunnelForm">
                                                    <input type="hidden" id="editFunnelId">
                                                    <div class="form-group">
                                                        <label for="editFunnelName">Название</label>
                                                        <input type="text" class="form-control" id="editFunnelName" required>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="editFunnelDescription">Описание</label>
                                                        <textarea class="form-control" id="editFunnelDescription" rows="3" required></textarea>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="editFunnelBudget">Бюджет</label>
                                                        <input type="text" class="form-control" id="editFunnelBudget" required>
                                                    </div>
                                                    <div class="text-center">
                                                        <button type="submit" class="btn btn-primary">Сохранить</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="tab-pane fade" id="editMetrics" role="tabpanel" aria-labelledby="editMetricsTab">
                                                <form id="addMetricForm">
                                                    <div class="form-group">
                                                        <label for="metricName">Название метрики</label>
                                                        <input type="text" class="form-control" id="metricName" required>
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="metricType">Тип метрики</label>
                                                        <select class="form-control" id="metricType" required>
                                                            <option value="youtube">YouTube просмотры</option>
                                                            <option value="webhook">Вебхук</option>
                                                            <option value="vk_ads">ВК реклама</option>
                                                            <option value="google_sheets">Гугл-таблица</option>
                                                            <option value="api">Запрос по API</option>
                                                            <option value="event">Счётчик действий на сайте</option>
                                                            <option value="telegram">Телеграм реклама</option>
                                                            <option value="formula">Формула</option>
                                                            <option value="yandex_metrika">Яндекс Метрика</option>
                                                        </select>
                                                    </div>
                                                    <div id="metricFields"></div>
                                                    <!-- <div class="text-center">
                                                        <button type="submit" class="btn btn-primary">Добавить метрику</button>
                                                    </div> -->
                                                </form>
                                                <div id="metricsContainer">
                                                    <div id="metricsList" class="mt-3"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="summaryContent" role="tabpanel" aria-labelledby="summary-tab">
                    <!-- Здесь будет сводная информация, пока пустая -->
                    <h3 class="text-center mt-4">Сводная информация</h3>
                    <p class="text-center">Пока пусто...</p>
                </div>
            </div>
            <div id="notification" class="notification" style="display: none;"></div>
        </body>
    </html>
  `;



  return (
    <div dangerouslySetInnerHTML={{ __html: fullHtml }} />
  );
};


export default Index;