import Message from './Message';

const Messages = ({ messages }) => {
    return (
        <>
            {messages?.filter(message => message.text !== null && message.text !== '').map(message => (
                <Message key={message.id} message={message} />
            ))}
        </>
    );
};

export default Messages;
