import React from "react";

import Dashboard from "./components/Dashboard/Index";

import Tickets from './components/Tickets/Tickets.jsx'
import AMO_leads from './components/AMO_leads/Leads.jsx'
import TicketChat from './components/Chat/Index.jsx'
import Users from './components/Users/Users.jsx'
import Profile from "./components/Profile/Index.jsx";
import Alerts from "./components/Alerts/Index";
import Users_data from "./components/Users_data/Index";
import Analytics from "./components/Analytics/Index";
import Funnels from "./components/Funnels/Index";

// export const apiUrl = 'https://telegram.firestorm.team/api'
export const apiUrl = 'https://telegram.firestorm.team/api'

export const ticket_statuses = {
    'new': 'Новый',
    'active': 'В работе',
    'closed': 'Закрыт'
}
export const profile_cards = [
    {'name': 'ID', 'key': 'id'},
    {'name': 'Почта', 'key': 'email'},
    {'name': 'Дата регистрации', 'key': 'create_at'},
]

export const routes = [
    {
        title: 'Главная',
        module: <Dashboard/>,
        link: '/',
        roles: [null, 1, 2, 3],
        navbar: true
    },
    {
        title: 'Тикеты',
        module: <Tickets/>,
        link: '/tickets',
        roles: [1, 2, 3],
        navbar: true},
    {
        title: 'Тикеты',
        module: <TicketChat/>,
        link: '/tickets',
        link_params: ['id'],
        roles: [1, 2, 3]
    },
    {
        title: 'Заявки АМО',
        module: <AMO_leads/>,
        link: '/leads',
        roles: [1, 2, 3],
        navbar: true
    },
    {
        title: 'Уведомления',
        module: <Alerts/>,
        link: '/alerts',
        roles: [1,2],
        navbar: true
    },
    {
        title: 'Пользователи',
        module: <Users/>,
        link: '/users',
        roles: [1],
        navbar: true
    },
    {
        title: 'Профиль пользователя',
        module: <Profile/>,
        link: '/profile',
        link_params: ['id'],
        roles: [1, 2, 3],
        navbar: false
    },
    {
        title: 'Фильтр юзеров',
        module: <Users_data/>,
        link: '/users_data',
        roles: [1, 2],
        navbar: true
    },
    {
        title: 'Аналитика',
        module: <Analytics/>,
        link: '/analytics',
        roles: [1, 2, 3],
        navbar: true
    },
    {
        title: 'Воронки',
        module: <Funnels/>,
        link: '/fpanel',
        roles: [1, 2],
        navbar: true
    }
]
