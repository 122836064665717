import {useContext} from "react";
import {authContext} from "../Context";
import {apiUrl} from '../../config';

const Message = ({message}) => {
    const {User} = useContext(authContext)
    const selfMessage = message.user_id === User.id
    if (message.user_id === -1) {
        const styles = {
            width: '100%',
            height: '0',
            border: '1px solid #919191',
            margin: '3px',
            display:'inline-block'}
        return (
            <li className=" mb-4 ">
                <div className="divider d-flex align-items-center mb-4 text-center">
                    <div style={styles}></div>
                    <p className="text-center mx-3 mb-0 w-100" style={{color: '#919191'}}>{message.date}<br />{message.text}</p>
                    <div style={styles}></div>

                </div>
            </li>
        )
    }
    return (

        <li className="d-flex justify-content-between mb-4 ">
            {!selfMessage && <img
                src={message.user_picture.startsWith('http') ? message.user_picture : apiUrl+'/static/img/avatars/'+message.user_picture}
                className="rounded-circle d-flex align-self-start m-3 shadow-1-strong" width="60"/>
            }
                <div className="card w-100">
                    <div className="card-header d-flex justify-content-between p-3">
                        <p className="fw-bold mb-0">{message.username}</p>
                        <p className="text-muted small mb-0"><i className="far fa-clock"></i> {message.date}</p>
                    </div>
                    <div className="card-body">
                        <p className="mb-0">
                            {message.text}
                        </p>
                    </div>
                </div>

            { selfMessage && <img
                    src={message.user_picture} className="rounded-circle d-flex align-self-start m-3 shadow-1-strong" width="60"/>}

        </li>
    )
}
export default Message;