import React, {useState, useEffect} from 'react';
import axios from "axios";
import DistributionByType from "./DistributionByType";
import DistributionByDate from "./DistributionByDate";
import {apiUrl} from "../../config";
import {getAuthCookie} from "../../modules";

const Index = () => {

    const [data, setData] = useState({
            "labels": ["SPIN", "MTT", "CASH"],
            "colors": ["#FF0000", "#FF7400", "#0b13e3"],
            "rows": [
                {
                    "total": 491,
                }, {
                    "total": 346,
                }, {
                    "total" : 325
                }],
            "dates": {
                "mtt": {
                    "label": "MTT",
                    "data": [33, 33, 16, 21, 8, 17, 47, 19],
                    "borderColor": "#FF6384FF",
                    "backgroundColor": "#FF63847F"
                },
                "date": {"data": ["22.08", "23.08", "24.08", "25.08", "26.08", "27.08", "28.08", "29.08"]},
                "cash": {
                    "label": "CASH",
                    "data": [54, 25, 28, 34, 17, 58, 16, 13],
                    "borderColor": "#35A2EBFF",
                    "backgroundColor": "#35A2EB7F"
                },
                "spin": {
                    "label": "SPIN",
                    "data": [43, 52, 32, 67, 20, 74, 10, 22],
                    "borderColor": "#ff8f0f",
                    "backgroundColor": "#f29429"
                }
            }
        }
    )

    // useEffect(()=>{
    //     axios.get(apiUrl+'/dashboard/',getAuthCookie())
    //         .then(resp => setData(resp.data))
    // },[])

    return (
        <div>
            <h1 className={'my-5 text-center'}>Статистика</h1>
            <div className={'row'}>
                <div className="col-3">
                    <DistributionByType data={data}/>
                </div>
                <div className="col-9">
                    <DistributionByDate data={data}/>
                </div>
            </div>

        </div>
    )
}
export default Index