import Picker from 'emoji-picker-react';
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { authContext } from "../Context";

import { ReactComponent as Smile } from "../../icons/emoji-smile-fill.svg";
import { ReactComponent as SendFill } from "../../icons/send-fill.svg";
import axios from "axios";
import { getAuthCookie } from "../../modules";
import { apiUrl } from "../../config";

const Send = ({ chat, setChat }) => {
    const ticket_id = useParams().id;
    const { User } = useContext(authContext);
    const [message, setMessage] = useState({
        text: '',
        files: []
    });
    const [pickerVisible, setPickerVisible] = useState(false);
    const [isUserInChat, setIsUserInChat] = useState(false);

    useEffect(() => {
        // Update isUserInChat whenever chat or User changes
        setIsUserInChat(chat.chat_members.includes(User.id));
    }, [chat.chat_members, User.id]);

    const onEmojiClick = (emojiObject, event) => {
        setMessage(msg => ({
            ...msg,
            text: msg.text + emojiObject.emoji,
        }));
    };

    const sendMessage = async () => {
        if (!message.text.trim()) return;

        try {
            await axios.post(apiUrl + '/tickets/add-message', {
                id: parseInt(ticket_id),
                text: message.text
            }, getAuthCookie());

            // Optionally, you may update the chat state here if needed

            setMessage({
                text: '',
                files: []
            });
            console.log('message sent');
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const joinChat = async () => {
        // Check if the user is already a member before sending the request
        if (isUserInChat) return;

        try {
            await axios.get(`${apiUrl}/tickets/join-chat/${ticket_id}`, getAuthCookie());
            // After successful join, update chat_members
            setChat(prevChat => ({
                ...prevChat,
                chat_members: [...prevChat.chat_members, User.id],
            }));
        } catch (error) {
            console.error("Error joining chat:", error);
        }
    };

    // Check if the current user is the creator
    const isUserCreator = chat.creator_id === User.id;

    // Now, handle rendering based on the conditions

    if (chat.status === 'closed') {
        return (
            <div className='fixed-bottom mb-2'>
                <div className='mx-auto' style={{ maxWidth: '900px' }}>
                    <button className='btn btn-lg btn-danger disabled p-3' disabled={true} style={{ width: '100%' }}>
                        Тикет был закрыт
                    </button>
                </div>
            </div>
        );
    } else if (chat.status === 'amo') {
        return (
            <div className='fixed-bottom mb-2'>
                <div className='mx-auto' style={{ maxWidth: '900px' }}>
                    <div
                        className='p-3'
                        style={{
                            width: '100%',
                            backgroundColor: '#d1ecf1', // Светло-синий фон (можно настроить)
                            color: '#0c5460',           // Цвет текста (можно настроить)
                            border: '1px solid #bee5eb', // Граница (можно настроить)
                            borderRadius: '0.25rem',     // Закругленные углы
                            textAlign: 'center',         // Центрирование текста
                            userSelect: 'text',          // Позволяет выделять текст
                            cursor: 'text',              // Курсор текста при наведении
                        }}
                    >
                        {chat.amo_text}
                    </div>
                </div>
            </div>
        );
    } else if (!isUserInChat && !isUserCreator) {
        // Show the join button if the user is neither in the chat nor the creator
        return (
            <div className='fixed-bottom mb-2'>
                <div className='mx-auto' style={{ maxWidth: '900px' }}>
                    <button className='btn btn-lg btn-primary p-3' style={{ width: '100%' }} onClick={joinChat}>
                        Присоединиться к чату
                    </button>
                </div>
            </div>
        );
    } else if (chat.status === 'new' || chat.status === 'active') {
        // Show the message input area
        return (
            <div className='mb-2 fixed-bottom'>
                <div className='mx-auto' style={{ maxWidth: '900px' }}>
                    {pickerVisible && (
                        <div style={{ position: 'absolute', right: '1000px', bottom: '150px' }}>
                            <Picker onEmojiClick={onEmojiClick} />
                        </div>
                    )}

                    <div className="card-footer text-muted d-flex justify-content-start align-items-center px-3 border rounded-3">
                        <a className={`m-2 ${!pickerVisible && "text-muted"}`} href="#" onClick={(e) => {
                            e.preventDefault();
                            setPickerVisible(state => !state);
                        }}>
                            <Smile />
                        </a>

                        <textarea
                            className="mx-1 form-control form-control-lg border rounded-3 m-3"
                            style={{ borderColor: 'transparent' }}
                            id="exampleFormControlInput1"
                            rows={4}
                            placeholder="Написать сообщение"
                            onChange={({ target: { value } }) => {
                                setMessage(msg => {
                                    return { ...msg, text: value };
                                });
                            }}
                            value={message.text}
                        />

                        <a className="ms-3 text-muted" href="#" onClick={(e) => {
                            e.preventDefault();
                            sendMessage();
                        }}>
                            <SendFill />
                        </a>
                    </div>
                </div>
            </div>
        );
    } else {
        // Handle any other statuses or default case
        return null;
    }
};

export default Send;
